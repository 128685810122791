<template>
  <div id="ratesStandard">
    <div class="ruleDetail-wrap">
      <div class="table-container">
        <div class="table-title">
          <div>
            <span class="dot"></span>
            正常
          </div>
          <div class="operation">
            <el-button
              type="primary"
              size="mini"
              v-if="
                normalRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-normal_calculation'
                )
              "
              @click="calculation(normalRule.priceId)"
              >计算</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-if="
                !normalRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-normal_addBtn'
                )
              "
              @click="addRule('普通')"
              >添加</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-if="
                normalRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-normal_delBtn'
                )
              "
              @click="deleteRule(normalRule.priceId, '普通')"
              >删除</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-if="
                normalRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-normal_editBtn'
                )
              "
              @click="editRule(normalRule.priceId, '普通')"
              >编辑</el-button
            >
          </div>
        </div>
        <div class="ruleDetail">
          <rule-preview
            v-if="normalRule"
            :baseForm="normalRule.baseForm"
            :chargeForm="normalRule.chargeForm"
            :detailForm="normalRule.detailForm"
            :priceType="normalRule.priceType"
            :periodHours="normalRule.baseForm.periodHours"
            :timeRuleForm="normalRule.timeRuleForm"
          />
          <span v-else>无</span>
        </div>
      </div>
      <div class="table-container">
        <div class="table-title">
          <div>
            <span class="dot"></span>
            业主
          </div>
          <div class="operation">
            <el-button
              type="primary"
              size="mini"
              v-if="
                !ownerRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-owner_addBtn'
                )
              "
              @click="addRule('业主')"
              >添加</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-if="
                ownerRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-owner_calculation'
                )
              "
              @click="calculation(ownerRule.priceId)"
              >计算</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-if="
                ownerRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-owner_delBtn'
                )
              "
              @click="deleteRule(ownerRule.priceId, '业主')"
              >删除</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-if="
                ownerRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-owner_editBtn'
                )
              "
              @click="editRule(ownerRule.priceId, '业主')"
              >编辑</el-button
            >
          </div>
        </div>
        <div class="ruleDetail">
          <rule-preview
            v-if="ownerRule"
            :baseForm="ownerRule.baseForm"
            :chargeForm="ownerRule.chargeForm"
            :detailForm="ownerRule.detailForm"
            :priceType="ownerRule.priceType"
            :periodHours="ownerRule.baseForm.periodHours"
            :timeRuleForm="ownerRule.timeRuleForm"
          />
          <span v-else>无</span>
        </div>
      </div>
    </div>
    <div class="ruleDetail-wrap">
      <div class="table-container">
        <div class="table-title">
          <div>
            <span class="dot"></span>
            充电
          </div>
          <div class="operation">
            <el-button
              type="primary"
              size="mini"
              v-if="
                !chargeRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-charge_addBtn'
                )
              "
              @click="addRule('充电')"
              >添加</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-if="
                chargeRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-charge_calculation'
                )
              "
              @click="calculation(chargeRule.priceId)"
              >计算</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-if="
                chargeRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-charge_delBtn'
                )
              "
              @click="deleteRule(chargeRule.priceId, '充电')"
              >删除</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-if="
                chargeRule &&
                buttonList.includes(
                  'parkingLotList-detail-ratesStandardTab-charge_editBtn'
                )
              "
              @click="editRule(chargeRule.priceId, '充电')"
              >编辑</el-button
            >
          </div>
        </div>
        <div class="ruleDetail">
          <rule-preview
            v-if="chargeRule"
            :baseForm="chargeRule.baseForm"
            :chargeForm="chargeRule.chargeForm"
            :detailForm="chargeRule.detailForm"
            :priceType="chargeRule.priceType"
            :periodHours="chargeRule.baseForm.periodHours"
            :timeRuleForm="chargeRule.timeRuleForm"
          />
          <span v-else>无</span>
        </div>
      </div>
      <div class="table-container"></div>
    </div>
    <charging-rules ref="chargingRules" @refreshData="refreshData" />
    <feeCalculation ref="feeCalculation" />
  </div>
</template>
<script>
export default {
  components: {
    rulePreview: () => import("@/components/chargingRules/rulePreview"),
    chargingRules: () => import("@/components/chargingRules/chargingRules.vue"),
    feeCalculation: () =>
      import("@/components/chargingRules/feeCalculation.vue"),
  },
  data() {
    return {
      //正常规则
      normalRule: {
        baseForm: {},
        chargeForm: {},
        detailForm: {},
      },
      //业主规则
      ownerRule: {
        baseForm: {},
        chargeForm: {},
        detailForm: {},
      },
      //充电规则
      chargeRule: {
        baseForm: {},
        chargeForm: {},
        detailForm: {},
      },
    };
  },
  created() {
    this.getNormalRule();
    this.getOwnerRule();
    this.getChargeRule();
  },
  methods: {
    calculation(priceId) {
      this.$refs.feeCalculation.priceId = priceId;
      this.$refs.feeCalculation.show = true;
    },
    deleteRule(priceId, priceVersion) {
      this.$confirm("此操作将永久删除该规则, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(`/price/delete/${priceId}`);
          if (res.code === 0) {
            this.$message.success("删除成功");
            if (priceVersion === "普通") {
              await this.getNormalRule();
            } else if (priceVersion === "业主") {
              await this.getOwnerRule();
            } else if (priceVersion === "充电") {
              await this.getChargeRule();
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    async editRule(priceId, priceVersion) {
      let key = "normalRule";
      if (priceVersion === "业主") {
        key = "ownerRule";
      } else if (priceVersion === "充电") {
        key = "chargeRule";
      }
      this.$refs.chargingRules.baseForm.priceVersion = priceVersion;
      this.$refs.chargingRules.priceId = priceId;
      //避免编辑的内容影响到详情的内容
      let data = JSON.parse(JSON.stringify(this[key]));
      Object.assign(this.$refs.chargingRules.baseForm, data.baseForm);
      Object.assign(this.$refs.chargingRules.chargeForm, data.chargeForm);
      if (data.priceType === "组合收费") {
        Object.assign(this.$refs.chargingRules.detailForm, data.detailForm);
      } else if (data.priceType === "按时长收费") {
        this.$refs.chargingRules.timeRuleForm = data.timeRuleForm;
      }
      this.$refs.chargingRules.show = true;
    },
    addRule(priceVersion) {
      this.$refs.chargingRules.baseForm.priceVersion = priceVersion;
      this.$refs.chargingRules.show = true;
    },
    refreshData() {
      this.getNormalRule();
      this.getOwnerRule();
      this.getChargeRule();
    },
    async getNormalRule() {
      try {
        let res = await this.$http.post(`/price/detail/byVersion`, {
          parkingLotId: this.$route.params.id,
          priceVersion: "普通",
        });
        if (res.code === 0) {
          if (res.data.priceType === "组合收费") {
            for (let item of res.data.priceDetailList) {
              item.timeEnd = String(item.timeEnd).padStart(4, "0");
              item.timeStart = String(item.timeStart).padStart(4, "0");
              for (let items of item.priceUnitList) {
                items.id = Math.random();
              }
            }
            this.$set(this, "normalRule", {
              priceId: res.data.priceId,
              baseForm: {
                priceName: res.data.priceName,
                parkingLotId: res.data.parkingLotId,
                priceVersion: res.data.priceVersion,
                countWithArrival: res.data.countWithArrival,
              },
              chargeForm: {
                freeDuration: res.data.freeDuration,
                allowDoubleCharge: res.data.allowDoubleCharge,
                freeUnit: res.data.freeUnit,
                freeIncluded: res.data.freeIncluded === 1,
                highestCharge: res.data.highestCharge,
                vehicleTopCharge: res.data.vehicleTopCharge,
                onceDuration: res.data.onceDuration,
                onceCharge: res.data.onceCharge,
                onceUnit: res.data.onceUnit,
                priceComment: res.data.priceComment,
                freeLeaveMinutes: res.data.freeLeaveMinutes,
              },
              detailForm: {
                priceDetailList: res.data.priceDetailList,
              },
              priceType: res.data.priceType,
            });
          } else if (res.data.priceType === "按时长收费") {
            this.$set(this, "normalRule", {
              priceId: res.data.priceId,
              baseForm: {
                parkingLotId: res.data.parkingLotId,
                priceName: res.data.priceName,
                priceType: res.data.priceType,
                periodHours: res.data.periodHours,
                priceVersion: res.data.priceVersion,
              },
              chargeForm: {
                freeDuration: res.data.freeDuration,
                freeUnit: res.data.freeUnit,
                allowDoubleCharge: res.data.allowDoubleCharge,
                freeIncluded: res.data.freeIncluded === 1,
                highestCharge: res.data.highestCharge,
                vehicleTopCharge: res.data.vehicleTopCharge,
                onceDuration: res.data.onceDuration,
                onceCharge: res.data.onceCharge,
                onceUnit: res.data.onceUnit,
                priceComment: res.data.priceComment,
                freeLeaveMinutes: res.data.freeLeaveMinutes,
              },
              detailForm: {},
              timeRuleForm: {
                priceUnitList: res.data.priceUnitList,
              },
              priceType: res.data.priceType,
            });
          }
        } else {
          this.normalRule = null;
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getOwnerRule() {
      try {
        let res = await this.$http.post(`/price/detail/byVersion`, {
          parkingLotId: this.$route.params.id,
          priceVersion: "业主",
        });
        if (res.code === 0) {
          for (let item of res.data.priceDetailList) {
            item.timeEnd = String(item.timeEnd).padStart(4, "0");
            item.timeStart = String(item.timeStart).padStart(4, "0");
            for (let items of item.priceUnitList) {
              items.id = Math.random();
            }
          }
          if (res.data.priceType === "组合收费") {
            this.$set(this, "ownerRule", {
              priceId: res.data.priceId,
              baseForm: {
                priceName: res.data.priceName,
                parkingLotId: res.data.parkingLotId,
                priceVersion: res.data.priceVersion,
                countWithArrival: res.data.countWithArrival,
              },
              chargeForm: {
                freeDuration: res.data.freeDuration,
                allowDoubleCharge: res.data.allowDoubleCharge,
                freeUnit: res.data.freeUnit,
                freeIncluded: res.data.freeIncluded === 1,
                highestCharge: res.data.highestCharge,
                vehicleTopCharge: res.data.vehicleTopCharge,
                onceDuration: res.data.onceDuration,
                onceCharge: res.data.onceCharge,
                onceUnit: res.data.onceUnit,
                priceComment: res.data.priceComment,
                freeLeaveMinutes: res.data.freeLeaveMinutes,
              },
              detailForm: {
                priceDetailList: res.data.priceDetailList,
              },
              priceType: res.data.priceType,
            });
          } else if (res.data.priceType === "按时长收费") {
            this.$set(this, "ownerRule", {
              priceId: res.data.priceId,
              baseForm: {
                parkingLotId: res.data.parkingLotId,
                priceName: res.data.priceName,
                priceType: res.data.priceType,
                periodHours: res.data.periodHours,
                priceVersion: res.data.priceVersion,
              },
              chargeForm: {
                freeDuration: res.data.freeDuration,
                allowDoubleCharge: res.data.allowDoubleCharge,
                freeUnit: res.data.freeUnit,
                freeIncluded: res.data.freeIncluded === 1,
                highestCharge: res.data.highestCharge,
                vehicleTopCharge: res.data.vehicleTopCharge,
                onceDuration: res.data.onceDuration,
                onceCharge: res.data.onceCharge,
                onceUnit: res.data.onceUnit,
                priceComment: res.data.priceComment,
                freeLeaveMinutes: res.data.freeLeaveMinutes,
              },
              detailForm: {},
              timeRuleForm: {
                priceUnitList: res.data.priceUnitList,
              },
              priceType: res.data.priceType,
            });
          }
        } else {
          this.ownerRule = null;
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getChargeRule() {
      try {
        let res = await this.$http.post(`/price/detail/byVersion`, {
          parkingLotId: this.$route.params.id,
          priceVersion: "充电",
        });
        if (res.code === 0) {
          for (let item of res.data.priceDetailList) {
            item.timeEnd = String(item.timeEnd).padStart(4, "0");
            item.timeStart = String(item.timeStart).padStart(4, "0");
            for (let items of item.priceUnitList) {
              items.id = Math.random();
            }
          }
          if (res.data.priceType === "组合收费") {
            this.$set(this, "chargeRule", {
              priceId: res.data.priceId,
              baseForm: {
                priceName: res.data.priceName,
                parkingLotId: res.data.parkingLotId,
                priceVersion: res.data.priceVersion,
                countWithArrival: res.data.countWithArrival,
              },
              chargeForm: {
                freeDuration: res.data.freeDuration,
                allowDoubleCharge: res.data.allowDoubleCharge,
                freeUnit: res.data.freeUnit,
                freeIncluded: res.data.freeIncluded === 1,
                highestCharge: res.data.highestCharge,
                vehicleTopCharge: res.data.vehicleTopCharge,
                onceDuration: res.data.onceDuration,
                onceCharge: res.data.onceCharge,
                onceUnit: res.data.onceUnit,
                priceComment: res.data.priceComment,
                freeLeaveMinutes: res.data.freeLeaveMinutes,
              },
              detailForm: {
                priceDetailList: res.data.priceDetailList,
              },
              priceType: res.data.priceType,
            });
          } else if (res.data.priceType === "按时长收费") {
            this.$set(this, "chargeRule", {
              priceId: res.data.priceId,
              baseForm: {
                parkingLotId: res.data.parkingLotId,
                priceName: res.data.priceName,
                priceType: res.data.priceType,
                periodHours: res.data.periodHours,
                priceVersion: res.data.priceVersion,
              },
              chargeForm: {
                freeDuration: res.data.freeDuration,
                allowDoubleCharge: res.data.allowDoubleCharge,
                freeUnit: res.data.freeUnit,
                freeIncluded: res.data.freeIncluded === 1,
                highestCharge: res.data.highestCharge,
                vehicleTopCharge: res.data.vehicleTopCharge,
                onceDuration: res.data.onceDuration,
                onceCharge: res.data.onceCharge,
                onceUnit: res.data.onceUnit,
                priceComment: res.data.priceComment,
                freeLeaveMinutes: res.data.freeLeaveMinutes,
              },
              detailForm: {},
              timeRuleForm: {
                priceUnitList: res.data.priceUnitList,
              },
              priceType: res.data.priceType,
            });
          }
        } else {
          this.chargeRule = null;
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>
<style lang="scss">
#ratesStandard {
  .ruleDetail-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .table-container {
      flex: 1;
      .table-title {
        display: flex;
        justify-content: space-between;
        color: white !important;
        .operation {
        }
      }
    }
  }
}
</style>
